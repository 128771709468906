import React, { useEffect, useState } from 'react';
import { Card, Flex } from 'antd';
import { getGallery } from '../../services/storage/images';
import { ga } from '../../services/analytics/analytics';

const MenuGallery: React.FC = () => {
    const [menu, setMenu] = useState<string[]>([]);
    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    // action on load component
    useEffect(() => {
        getGallery(setMenu, 'landing/menu');
    }, []);

    return (<Flex wrap gap={10} className='menu-gallery'>
        {menu?.map((src: string) => <Card key={src}
            onClick={() => event('click-menu-item')}
            className='menu-gallery-item'
            cover={<img alt="menu-gallery-picture" src={src} />}>
        </Card>) ?? []}
    </Flex>);
}

export default MenuGallery;