import React, { useEffect } from 'react';
import { Timeline } from 'antd';
import {  } from '@ant-design/icons';

const HowItWorks: React.FC = () => {
    // action on load component
    useEffect(() => {
    }, []);

    return (<Timeline className='how-it-works' 
        mode='alternate'
        items={[
            {
                color: '#8d3251',
                label: (<h3>Entra a nuestra <a style={{color: '#fe5a92'}} href='https://app.tlac.com.mx'>tienda online</a> y crea/inicia tu cuenta ya sea correo o google</h3>),
                children: (<img src={require('../../assets/img/how-it-works/signin-signup.png')} alt='step-1' className='how-it-works-item-pic' style={{borderRadius: 20}} />)
            },
            {
                color: '#8d3251',
                label: (<h3>Selecciona el paquete que más te guste</h3>),
                children: (<img src={require('../../assets/img/how-it-works/select-pack.gif')} alt='step-2' className='how-it-works-item-pic' style={{borderRadius: 30}} />)
            },
            {
                color: '#8d3251',
                label: (<h3>Planea tu semana agregando platillos a tu paquete segun tus preferencias dieteticas</h3>),
                children: (<img src={require('../../assets/img/how-it-works/select-dish.gif')} alt='step-2' className='how-it-works-item-pic' style={{borderRadius: 30}} />)
            },
            {
                color: '#8d3251',
                label: (<h3>Finaliza tu orden agregando/confirmando la direccion y hora de entrega</h3>),
                children: (<img src={require('../../assets/img/how-it-works/confirm-order.gif')} alt='step-3' className='how-it-works-item-pic' style={{borderRadius: 35}} />)
            },
            {
                color: '#8d3251',
                label: (<h3>Espera pacientemente hasta la siguiente semana para que empiecen a llegar tus tlacs</h3>),
                children: (<img src={require('../../assets/img/how-it-works/delivery.jpg')} alt='step-3' className='how-it-works-item-pic' style={{borderRadius: '100%'}} />)
            },
            {
                color: '#8d3251',
                label: (<h3>Calienta y disfruta tu comida!</h3>),
                children: (<img src={require('../../assets/img/how-it-works/food-served.jpg')} alt='step-3' className='how-it-works-item-pic' style={{borderRadius: '100%'}} />)
            },
        ]}
    />);
}

export default HowItWorks;