import React from 'react';
import { Carousel } from 'antd';
import { } from '@ant-design/icons';
import { ga } from '../../services/analytics/analytics';

const CarouselContent: React.FC<{name: string, count: number}> = ({ name, count }) => {
  const event = (name: string) => {
    ga(name, {
        path: window.location.href, 
        title: document.title, 
        userType: 'visitor',
        userAgent: navigator.userAgent
    });
  }
  
    return (<Carousel autoplay arrows className={`carousel-content ${name}`} >
      {Array.from({length: count}).map((_, i) => <div key={`carousel-content-item-${i}`} onClick={() => event('click-carousel-item')}>
        <h3 
          className='carousel-content-item-panel' 
          style={{
            backgroundImage: `url(${require(`../../assets/img/carousel/${name}-${i + 1}.jpg`)})`
          }}
        >
        </h3>
      </div>)}
  </Carousel>)
}

export default CarouselContent;