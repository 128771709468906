import React, { useEffect } from 'react';
import './index.scss';
import { Breadcrumb, Card, Flex, Layout } from 'antd';
import HeaderComponent from '../../components/core/header/Index';
import { Content } from 'antd/es/layout/layout';
import { getColorsFromConfig } from '../../services/remote-config/config';
import FooterComponent from '../../components/core/footer/Index';

const CalculatorPage: React.FC = () => {
    // action on load page
    useEffect(() => {
        document.title = "TLAC / Calculadora"
        getColorsFromConfig();
    }, []);

    return (<Layout className='calculator page'>
        <HeaderComponent hasMenu={false} />
        <Content>
            <Breadcrumb>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item>Calculadora de precios</Breadcrumb.Item>
            </Breadcrumb>
            <br></br>
            <Card className='calculator'>
                <Flex justify='center'>
                    <img alt='aun-no-disponible' src={require('../../assets/img/keep-calm-and-estamos-trabajando-para-usted.png')} style={{borderRadius: 20}} />
                </Flex>
            </Card>
        </Content>
        <FooterComponent />
    </Layout>);
}

export default CalculatorPage;