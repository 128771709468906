import { ref, listAll, getDownloadURL, list } from "firebase/storage";
import { storage } from "../sdk";

export async function getGallery(setSrcList: React.Dispatch<React.SetStateAction<string[]>>, path: string): Promise<void> {
    const storageRef = ref(storage, path);
    const res = await listAll(storageRef);
    const urls = await Promise.all(res.items.map(itemRef => getDownloadURL(itemRef)));
    setSrcList(urls);
}

export async function getPic(setSrc: React.Dispatch<React.SetStateAction<string>>, path: string): Promise<void> {
    const storageRef = ref(storage, path);
    const res = await list(storageRef, { maxResults: 1 });
    if (!res.items.length) return
    setSrc(await getDownloadURL(res.items[0]))
}