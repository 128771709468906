import React, { useEffect } from 'react';
import './index.scss';
import { Breadcrumb, Card, Layout } from 'antd';
import HeaderComponent from '../../components/core/header/Index';
import { Content } from 'antd/es/layout/layout';
import { getColorsFromConfig } from '../../services/remote-config/config';
import FooterComponent from '../../components/core/footer/Index';
import { ga } from '../../services/analytics/analytics';

const TermsConditionsPage: React.FC = () => {
    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    // action on load page
    useEffect(() => {
        document.title = "TLAC / Terminos y Condiciones"
        getColorsFromConfig();
        event('view-terms-conditions');
    }, []);

    return (<Layout className='privacy page'>
        <HeaderComponent hasMenu={false} />
        <Content>
            <Breadcrumb>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item>Terminos y Condiciones</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Términos y Condiciones</h2>
            <Card>
            Aceptación de los Términos y Condiciones: Al acceder y utilizar los servicios de TLAC, el Usuario acepta y reconoce haber leído y entendido los términos y condiciones de este Contrato y acuerda cumplir con ellos.
            <h3>Servicios ofrecidos:</h3> TLAC es una plataforma en línea de entrega de comidas que ofrece planes de comidas semanales y una variedad de opciones de comidas. La selección de los planes de comidas y los detalles específicos de los productos ofrecidos por la TLAC se proporcionan en la página web de TLAC y están sujetos a cambios sin previo aviso.
            <h3>Registro del Usuario:</h3> Para utilizar los servicios de TLAC, el Usuario debe registrarse y crear una cuenta proporcionando información precisa y completa, incluyendo nombre, dirección de correo electrónico, dirección física y número de teléfono. El Usuario es responsable de mantener la seguridad de su cuenta y la confidencialidad de su información de inicio de sesión.
            <h3>Uso del servicio:</h3> El Usuario reconoce y acepta que los productos ofrecidos por TLAC están destinados exclusivamente para uso personal y no comercial. El Usuario se compromete a no revender, sublicenciar, transferir o distribuir los productos de TLAC.
            <h3>Pago y facturación:</h3> TLAC cobra al Usuario una tarifa por cada plan de comida seleccionado. El Usuario acepta pagar la tarifa correspondiente a través del método de pago proporcionado en su cuenta. TLAC se reserva el derecho de cambiar las tarifas en cualquier momento previo aviso al Usuario.
            <h3>Entrega de productos:</h3> TLAC se compromete a entregar los productos seleccionados por el Usuario a la dirección proporcionada en la cuenta. La entrega se realiza en la fecha y hora programadas previamente. Si el Usuario no está disponible en el momento de la entrega, TLAC puede intentar una segunda entrega o dejar los productos en un lugar seguro determinado por el Usuario. TLAC no se hace responsable de los productos entregados en un lugar seguro si el Usuario no se encuentra disponible en el momento de la entrega.
            <h3>Cancelaciones y reembolsos:</h3> El Usuario puede cancelar su suscripción en cualquier momento antes del cierre del plazo correspondiente. TLAC se reserva el derecho de cancelar la suscripción del Usuario en cualquier momento.
            <h3>Propiedad intelectual:</h3> Todos los contenidos, materiales y servicios ofrecidos por TLAC, incluyendo sin limitación, el software, textos, imágenes, diseños, fotografías, videos, gráficos, música, logos, marcas comerciales y nombres comerciales (en adelante "Contenido"), son propiedad exclusiva de TLAC o sus licenciantes. El Usuario reconoce y acepta que no tiene derecho a utilizar, reproducir, modificar o distribuir el Contenido sin la autorización previa y por escrito de TLAC.
            <h3>Limitación de responsabilidad:</h3> TLAC no será responsable por cualquier daño directo, indirecto, incidental, especial o consecuente que resulte del uso de los servicios de TLAC o de la imposibilidad de utilizarlos.
            La responsabilidad de TLAC por cualquier Indemnización: El Usuario acepta indemnizar, defender y mantener indemne a TLAC, sus afiliados, directores, empleados y agentes de cualquier reclamo, demanda, acción, pérdida, daño, costo o gasto, incluyendo honorarios razonables de abogados, que surjan del uso que el Usuario haga de los servicios de TLAC o de su incumplimiento de los términos y condiciones de este Contrato.

            Renuncia: La falta de cumplimiento por parte de TLAC de cualquier disposición de este Contrato no se interpretará como una renuncia a dicha disposición. La renuncia a cualquier disposición de este Contrato sólo tendrá efecto si se realiza por escrito y es firmada por un representante autorizado de TLAC.

            Derechos de terceros: Este Contrato no confiere ningún derecho o beneficio a ninguna persona o entidad que no sea el Usuario o TLAC.

            TLAC se compromete a brindar un servicio de calidad a sus Usuarios. En caso de que el Usuario tenga alguna queja o inconformidad relacionada con los servicios proporcionados por TLAC, se establece el siguiente procedimiento de quejas:

            El Usuario deberá presentar su queja por escrito, detallando de manera clara y precisa los motivos de la misma, así como cualquier información relevante que respalde su reclamación.

            La queja deberá ser enviada a TLAC a través de los medios de contacto proporcionados en la página web de TLAC.

            TLAC se compromete a revisar y analizar la queja de manera diligente y a tomar las medidas adecuadas para resolverla en un plazo razonable.

            TLAC se comunicará con el Usuario para informarle sobre las acciones tomadas para resolver la queja y, en caso necesario, solicitar información adicional o proporcionar aclaraciones.

            TLAC hará todos los esfuerzos razonables para resolver la queja de manera satisfactoria para ambas partes. Sin embargo, en caso de que no se llegue a un acuerdo, las partes podrán recurrir a los medios alternativos de resolución de conflictos mencionados en la sección "Resolución de conflictos" de este Contrato.

            Condiciones de Almacenamiento:

            TLAC se compromete a garantizar las condiciones adecuadas de almacenamiento de los productos ofrecidos a los Usuarios. A continuación se establecen las siguientes condiciones de almacenamiento:

            TLAC se asegurará de que los productos perecederos sean almacenados y transportados a temperaturas seguras para su consumo, de acuerdo con las regulaciones sanitarias aplicables.

            TLAC utilizará métodos de almacenamiento adecuados para mantener la frescura y calidad de los productos durante su almacenamiento y transporte.

            TLAC realizará inspecciones regulares de los lugares de almacenamiento y tomará medidas correctivas en caso de identificar condiciones inadecuadas que puedan afectar la seguridad o calidad de los productos.

            TLAC se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con el almacenamiento y transporte de alimentos y bebidas hasta que salen del almacén/cocina

            Responsabilidad por Daños:

            TLAC reconoce que pueden surgir situaciones en las que se produzcan daños como resultado del uso de sus servicios. A continuación se establecen las siguientes disposiciones con respecto a la responsabilidad por daños:

            TLAC no será responsable por cualquier daño directo, indirecto, incidental, especial o consecuente que resulte del uso de los servicios de TLAC o de la imposibilidad de utilizarlos. La responsabilidad de TLAC por cualquier Indemnización: El Usuario acepta indemnizar, defender y mantener indemne a TLAC, sus afiliados, directores, empleados y agentes de cualquier reclamo, demanda, acción, pérdida, daño, costo o gasto, incluyendo honorarios razonables de abogados, que surjan del uso que el Usuario haga de los servicios de TLAC o de su incumplimiento de los términos y condiciones de este Contrato.
            <h3>Renuncia:</h3> La falta de cumplimiento por parte de TLAC de cualquier disposición de este Contrato no se interpretará como una renuncia a dicha disposición. La renuncia a cualquier disposición de este Contrato sólo tendrá efecto si se realiza por escrito y es firmada por un representante autorizado de TLAC.
            <h3>Derechos de terceros:</h3> Este Contrato no confiere ningún derecho o beneficio a ninguna persona o entidad que no sea el Usuario o TLAC.
            <h3>Procedimiento de quejas:</h3> El Usuario deberá presentar su queja por escrito, detallando de manera clara y precisa los motivos de la misma, así como cualquier información relevante que respalde su reclamación. La queja deberá ser enviada a TLAC a través de los medios de contacto proporcionados en la página web de TLAC. TLAC se compromete a revisar y analizar la queja de manera diligente y a tomar las medidas adecuadas para resolverla en un plazo razonable. TLAC se comunicará con el Usuario para informarle sobre las acciones tomadas para resolver la queja y, en caso necesario, solicitar información adicional o proporcionar aclaraciones. TLAC hará todos los esfuerzos razonables para resolver la queja de manera satisfactoria para ambas partes. Sin embargo, en caso de que no se llegue a un acuerdo, las partes podrán recurrir a los medios alternativos de resolución de conflictos mencionados en la sección "Resolución de conflictos" de este Contrato.
            <h3>Condiciones de Almacenamiento:</h3> TLAC se compromete a garantizar las condiciones adecuadas de almacenamiento de los productos ofrecidos a los Usuarios. A continuación se establecen las siguientes condiciones de almacenamiento: TLAC se asegurará de que los productos perecederos sean almacenados y transportados a temperaturas seguras para su consumo, de acuerdo con las regulaciones sanitarias aplicables. TLAC utilizará métodos de almacenamiento adecuados para mantener la frescura y calidad de los productos durante su almacenamiento y transporte. TLAC realizará inspecciones regulares de los lugares de almacenamiento y tomará medidas correctivas en caso de identificar condiciones inadecuadas que puedan afectar la seguridad o calidad de los productos. TLAC se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con el almacenamiento y transporte de alimentos y bebidas hasta que salen del almacén/cocina.
            <h3>Responsabilidad por Daños:</h3> TLAC reconoce que pueden surgir situaciones en las que se produzcan daños como resultado del uso de sus servicios. A continuación se establecen las siguientes disposiciones con respecto a la responsabilidad por daños: TLAC no será responsable por ningún daño directo, indirecto, incidental, especial o consecuente que pueda surgir del uso de los servicios de TLAC, incluyendo, pero no limitado a, daños por pérdida de beneficios, interrupción de negocios, pérdida de datos o daños a la propiedad. En caso de que TLAC sea considerado responsable por cualquier daño, la responsabilidad total de TLAC estará limitada al monto pagado por el Usuario por los servicios correspondientes en el periodo de tres meses anteriores al evento que dio lugar al daño. TLAC no será responsable por los daños que puedan surgir como resultado de la conducta de terceros, incluyendo otros Usuarios de TLAC o proveedores de servicios externos. Estas disposiciones con respecto a la responsabilidad por daños se aplicarán en la medida máxima permitida por la ley aplicable.
            <h3>Responsabilidad por enfermedades y alergias alimentarias:</h3> TLAC se esforzará por garantizar que todos los alimentos y bebidas ofrecidos a los clientes sean seguros para el consumo humano. Sin embargo, TLAC no puede garantizar la seguridad absoluta de los alimentos y bebidas ofrecidos a los clientes, ya que ciertos riesgos inherentes a la producción, almacenamiento y transporte de alimentos y bebidas pueden existir. Por lo tanto, el cliente acepta que TLAC no será responsable de ninguna enfermedad, lesión o daño que pueda surgir del consumo de los alimentos y bebidas ofrecidos por TLAC. Además, el cliente acepta que renuncia a cualquier derecho a demandar a TLAC por dicha enfermedad, lesión o daño. TLAC se compromete a tomar todas las medidas razonables para garantizar la seguridad de los alimentos y bebidas ofrecidos a los clientes, incluyendo la adopción de prácticas de higiene y seguridad alimentaria adecuadas. Sin embargo, el cliente reconoce y acepta que ciertos riesgos pueden existir y que TLAC no puede garantizar la seguridad absoluta de los alimentos y bebidas ofrecidos. Además, TLAC se reserva el derecho de retirar cualquier producto de su oferta en cualquier momento si cree que existe un riesgo para la salud y seguridad del consumidor. TLAC también se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con la producción, almacenamiento, transporte y venta de alimentos y bebidas. En caso de que surja una reclamación relacionada con la seguridad de los alimentos y bebidas ofrecidos por TLAC, TLAC se compromete a investigar la reclamación de buena fe y tomar las medidas adecuadas para abordar cualquier problema de seguridad alimentaria que pueda haber surgido. Sin embargo, TLAC no acepta ninguna responsabilidad por cualquier reclamo relacionado con la seguridad de los alimentos y bebidas ofrecidos por TLAC y se reserva el derecho a defenderse de cualquier reclamación presentada en su contra.
            <h3>Idioma:</h3> Este Contrato se ha redactado en español y cualquier traducción del mismo se proporciona únicamente como una cortesía. En caso de cualquier conflicto entre la versión en español y una versión traducida, prevalecerá la versión en español.
            <h3>Contacto:</h3> Si el Usuario tiene alguna pregunta sobre este Contrato o los servicios ofrecidos por TLAC, puede ponerse en contacto con TLAC a través de la información de contacto proporcionada en la página web de TLAC.
            <h3>Aceptación:</h3> Al acceder y utilizar los servicios de TLAC, el Usuario reconoce haber leído, entendido y aceptado los términos y condiciones de este Contrato y se compromete a cumplir con ellos.
            <h3>Resolución de conflictos:</h3> Este contrato se regirá e interpretará de acuerdo con las leyes mexicanas, sin considerar conflictos de leyes. Cualquier disputa que surja de este contrato será resuelta por medios alternativos de resolución de conflictos, como la mediación o el arbitraje, de acuerdo con las reglas del Centro de Arbitraje mexicano. Si las partes no pueden resolver el conflicto por medios alternativos, las partes acuerdan someterse a la jurisdicción exclusiva de los tribunales de Tijuana, Baja California.
            <h3>Acuerdo completo:</h3> Este contrato constituye el acuerdo completo entre TLAC y el usuario en relación con el acceso y uso de los servicios de TLAC y reemplaza cualquier acuerdo anterior entre las partes. Si alguna disposición de este contrato es considerada inválida o inaplicable, dicha disposición será eliminada y las demás disposiciones permanecerán en pleno vigor y efecto.
            </Card>
        </Content>
        <FooterComponent />
    </Layout>);
}

export default TermsConditionsPage;