import React, { useEffect, useState } from 'react';
import { Badge, Card, Flex } from 'antd';
import { getConfigJson } from '../../services/remote-config/config';
import Pack from '../../models/pack';
import { ga } from '../../services/analytics/analytics';
const { Meta } = Card;

const Packs: React.FC = () => {
    const [packs, setPacks] = useState<Pack[] | null>([]);

    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    // action on load component
    useEffect(() => {
        getConfigJson<Pack[] | null>('packs', setPacks);
    }, []);

    return (<>
    <Flex justify='space-around' wrap gap={10} className='packs'>
        {packs?.filter(f => f.isCooked).map((pack: Pack) => <Badge.Ribbon text="Mealpreps" color='#8d3251'><Card 
            className='packs-item'
            cover={<img alt="pack-picture" src={require(`../../assets/img/packs/${pack.pic}`)} />}
            onClick={() => {
                event('click-pack')
                window.location.href = 'https://app.tlac.com.mx'
            }}
            hoverable >
                <Meta title={<span>{`$${pack.price}`} <small>MXN</small></span>} className='packs-item-price' />
                <Meta title={pack.name} description={pack.description} />
        </Card></Badge.Ribbon>) ?? []}
    </Flex>
    <Flex justify='space-around' wrap gap={10} className='packs'>
        {packs?.filter(f => !f.isCooked).map((pack: Pack) => <Badge.Ribbon text="Ingredientes" color='green'><Card 
            className='packs-item'
            cover={<img alt="pack-picture" src={require(`../../assets/img/packs/${pack.pic}`)} />}
            onClick={() => {
                event('click-pack')
                window.location.href = 'https://app.tlac.com.mx'
            }}
            hoverable >
                <Meta title={<span>{`$${pack.price}`} <small>MXN</small></span>} className='packs-item-price' />
                <Meta title={pack.name} description={pack.description} />
        </Card></Badge.Ribbon>) ?? []}
    </Flex>
    </>);
}

export default Packs;