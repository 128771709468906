import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { ShoppingOutlined } from '@ant-design/icons';
const { Header } = Layout;

const HeaderComponent: React.FC<{hasMenu: boolean | undefined}> = ({hasMenu = false}) => {
    const [menuItems] = useState<ItemType<MenuItemType>[] | undefined>(hasMenu ?
        ['Nosotros', 'Precios', '¿Cómo funciona?', 'Menu', 'Contacto'].map((label:string, i: number) =>
        ({
            key: `item${i}`,
            label,
            theme: 'light',
            onClick: () => {
                const section = label
                    .replace('¿','')
                    .replace('?','')
                    .replace(' ','')
                    .replace('ó','o');
                window.location.href = `#${section}`;
            },
        })
        ) : []);

    // action on load component
    useEffect(() => {
    }, []);

    return (<Header className='header'>
        <Menu 
            theme='light'
            mode='horizontal'
            items={menuItems}
            className='header-menu'
            defaultSelectedKeys={['2']}
        />
        <img 
            className='header-logo' 
            alt='logo' 
            src={require('../../../assets/img/logo-horizontal-dark.png')} 
            height={30} 
            onClick={() => document.location.href = '/'}
        />
        <div className='header-actions'>
            <Button type='primary' className='header-actions-btn' icon={<ShoppingOutlined />} href='https://app.tlac.com.mx'>Tienda</Button>
        </div>
    </Header>);
}

export default HeaderComponent;