import React, { useEffect, useState } from 'react';
import { Flex, Layout } from 'antd';
import { FacebookFilled, InstagramFilled, WhatsAppOutlined } from '@ant-design/icons';
import { getConfigJson } from '../../../services/remote-config/config';
import { ga } from '../../../services/analytics/analytics';
const { Footer } = Layout;

const FooterComponent: React.FC = () => {
    const [social, setSocial] = useState<{facebook: string, instagram: string, whatsapp: string} | null>(null)
    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    
    // action on load component
    useEffect(() => {
        getConfigJson('socialMedia', setSocial);
    }, []);

    return (<Footer className='footer'>
        <Flex justify='space-around'>
            <Flex gap={5} className='footer-legal'>
                <a href='/terms-and-conditions'>Terminos y Condiciones</a> 
                 | 
                <a href='/privacy-policy'>Política de Privacidad</a>
            </Flex>
            <Flex gap={10}>
                <a href={social?.facebook} onClick={() => event('click-facebook')}><FacebookFilled className='face icon' /></a>
                <a href={social?.instagram} onClick={() => event('click-instagram')}><InstagramFilled className='insta icon' /></a>
                <a href={social?.whatsapp} onClick={() => event('click-whatsapp')}><WhatsAppOutlined className='whats icon'/></a>
            </Flex>
        </Flex>
    </Footer>);
}

export default FooterComponent;