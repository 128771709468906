import React, { useEffect, useState } from 'react';

interface ScrollingTextProps {
  direction?: 'left-to-right' | 'right-to-left';
  speed?: number;
  startFrom?: 'left' | 'right';
  children: React.ReactNode;
  initY: number,
  endY: number
}

const ScrollingText: React.FC<ScrollingTextProps> = ({
  direction = 'right-to-left',
  speed = 10,
  startFrom = 'left',
  initY,
  endY,
  children,
}) => {

  const [translate, setTranslate] = useState(direction === 'left-to-right' ? 'translateX(-40px)' : 'translate(120%)');

  useEffect(() => {
    const onScroll = (e: Event) => {
      if (window.scrollY >= initY && window.scrollY <= endY) {
        const percent = ((window.scrollY - initY) / 900 * 100);
        setTranslate(direction === 'left-to-right' ? `translate(${(percent / 2) - 30}%)` : `translate(${(120 - percent) + 30}%)`);
      }
    }

    window.addEventListener('scroll', onScroll);
  }, [direction, endY, initY]);

  return (
    <div className={`scrolling-container ${direction} ${startFrom}`}>
      <div className='scrolling-text' style={{transform: translate}}>
        <h2 className='scrolling-text-content'>{children}</h2>
      </div>
    </div>
  );
};

export default ScrollingText;
