import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/Index';
import PrivacyPage from './pages/privacy/Index';
import TermsConditionsPage from './pages/terms-conditions/Index';
import CalculatorPage from './pages/calculator/Index';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/privacy-policy" element={<PrivacyPage/>} />
      <Route path="/terms-and-conditions" element={<TermsConditionsPage/>} />
      <Route path="/calculator" element={<CalculatorPage />} />
    </Routes>
  );
};

export default Router;