import React, { useEffect } from 'react';
import './index.scss';
import { Breadcrumb, Card, Layout } from 'antd';
import HeaderComponent from '../../components/core/header/Index';
import { Content } from 'antd/es/layout/layout';
import { getColorsFromConfig } from '../../services/remote-config/config';
import FooterComponent from '../../components/core/footer/Index';
import { ga } from '../../services/analytics/analytics';

const PrivacyPage: React.FC = () => {
    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    // action on load page
    useEffect(() => {
        document.title = "TLAC / Privacidad"
        getColorsFromConfig();
        event('view-privacy-policy')
    }, []);

    return (<Layout className='privacy page'>
        <HeaderComponent hasMenu={false} />
        <Content>
            <Breadcrumb>
                <Breadcrumb.Item href='/'>TLAC</Breadcrumb.Item>
                <Breadcrumb.Item>Política de privacidad</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Política de Privacidad para Tlac</h2>

            <p>Fecha de efectividad: {new Date('Fri Jan 12 2024 22:14:41 GMT-0800 (hora estándar del Pacífico)').toLocaleDateString('es-ES')}</p>
            <Card>
            <h2>1. Introducción</h2>

<p>¡Bienvenido a Tlac! Nos complace que utilices nuestro servicio de entrega de kits de comida. En Tlac, valoramos tu privacidad y nos comprometemos a proteger la información personal que compartes con nosotros. Esta Política de Privacidad describe detalladamente cómo recopilamos, utilizamos, almacenamos y protegemos tu información personal cuando accedes y utilizas nuestra aplicación. Al utilizar nuestros servicios, aceptas las prácticas descritas en esta política.</p>

<h2>2. Información que Recopilamos</h2>

<p>Recopilamos los siguientes tipos de información:</p>
<ul>
    <li>Información Personal: Recopilamos el correo electrónico, nombre, ID y foto de perfil del usuario con el único propósito de autenticar al usuario. No se utiliza ningún dato sensible para la recopilación interna de datos.</li>
    <li>Datos de Uso: Internamente, recopilamos eventos de la plataforma para mejorar la experiencia del usuario y para la mejora continua. No se utiliza ningún dato sensible del usuario para este propósito.</li>
</ul>

<h2>3. Cómo Usamos Su Información</h2>

<p>Utilizamos la información recopilada para los siguientes propósitos:</p>
<ul>
    <li>Autenticar a los usuarios mediante su correo electrónico, nombre, ID y foto de perfil a través de la función signInWithPopup de Firebase.</li>
    <li>Recopilación interna de datos: Recopilamos eventos de la plataforma para analizar las interacciones del usuario y mejorar continuamente la plataforma. No se utiliza ningún dato sensible del usuario para este propósito.</li>
</ul>

<h2>4. Compartir Su Información</h2>

<p>No vendemos, intercambiamos ni transferimos su información personal a terceros. La información solo se comparte con proveedores de servicios de confianza para ayudarnos a proporcionar nuestros servicios.</p>

<h2>5. Seguridad</h2>

<p>Damos prioridad a la seguridad de su información personal y empleamos medidas estándar de la industria para protegerla.</p>

<h2>6. Sus Opciones</h2>

<p>En Tlac, respetamos su privacidad y le ofrecemos las siguientes opciones:</p>

<ol>
    <li><strong>Acceso a Datos:</strong> Puede solicitar acceso a la información personal que tenemos sobre usted. Para consultas sobre sus datos, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</li>

    <li><strong>Eliminación de Datos:</strong> Si desea eliminar su cuenta o cierta información personal, puede hacerlo a través de la configuración de su cuenta. Además, tiene la opción de contactarnos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a> para solicitar la eliminación de su cuenta y los datos asociados. Tenga en cuenta que ciertos datos pueden retenerse por motivos legales o legítimos incluso después de la eliminación de la cuenta.</li>
</ol>

<p>Las comunicaciones externas se limitan a alertas esenciales de entrega por correo electrónico como parte de nuestro servicio. Los usuarios pueden recibir notificaciones relacionadas con sus pedidos, pero no participamos en correos electrónicos promocionales o publicidad fuera de la plataforma. Para cualquier inquietud sobre las preferencias de comunicación externa, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>

<h2>7. Autenticación mediante Gmail</h2>

<p>Tenga en cuenta que Tlac utiliza la autenticación de Gmail para el inicio de sesión de usuario. Al optar por autenticarse mediante correo electrónico/contraseña, está utilizando los servicios de Gmail para el proceso de autenticación.</p>

<h2>8. Cambios en Esta Política de Privacidad</h2>

<p>Esta Política de Privacidad puede actualizarse periódicamente. Por favor, revise esta página para cualquier cambio.</p>

<h2>9. Contáctenos</h2>

<p>Si tiene alguna pregunta o inquietud con respecto a esta Política de Privacidad, contáctenos en <a href="mailto:tlac.oficial@gmail.com">tlac.oficial@gmail.com</a>.</p>
            </Card>
        </Content>
        <FooterComponent />
    </Layout>);
}

export default PrivacyPage;