import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { logEvent, getAnalytics } from 'firebase/analytics'
import { getRemoteConfig, getValue, fetchConfig, RemoteConfig, activate } from 'firebase/remote-config'

const app = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
});
const db = getFirestore(app);
const storage = getStorage(app)
const remoteConfig: RemoteConfig = getRemoteConfig(app);

export { db, storage, remoteConfig, getValue, fetchConfig, activate, logEvent, getAnalytics }