import { activate, fetchConfig, getValue, remoteConfig } from '../sdk'

export async function getConfigString(key: string, set: React.Dispatch<React.SetStateAction<string>>): Promise<void> {
    await fetchConfig(remoteConfig);
    await activate(remoteConfig);
    const value = getValue(remoteConfig, key).asString();
    set(value);
}

export async function getConfigJson<T>(key: string, set: React.Dispatch<React.SetStateAction<T | null>>): Promise<void> {
    try {
        await fetchConfig(remoteConfig);
        await activate(remoteConfig);
        const jsonString = getValue(remoteConfig, key).asString();
        const jsonData = JSON.parse(jsonString) as T;
        console.log(jsonData)
        set(jsonData);
    } catch (error) {
        console.error('Error fetching or parsing config:', error);
        set(null);
    }
}

export async function getColorsFromConfig(): Promise<void> {
    if (document.documentElement.style.getPropertyValue('--colors-configured') === 'true') {
        return;
    }
    
    try {
        await fetchConfig(remoteConfig);
        await activate(remoteConfig);

        const jsonString = getValue(remoteConfig, 'colors').asString();
        const colors = JSON.parse(jsonString);
        console.log(colors)
        const root = document.documentElement;
        for (const [key, value] of Object.entries(colors)) {
            root.style.setProperty(`--${key}`, value as string);
        }
        // set flag on colors configured
        root.style.setProperty('--colors-configured', 'true');
    } catch (error) {
        console.error('Error fetching or applying colors:', error);
    }
}